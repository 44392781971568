export const logs = [
    {
        version: '0.5.0',
        date: '2024/1/23 16:10',
        message: 
            `
            【機能追加】<br>
            <ul>
                <li>予測モデルの追加（推論モジュール追加可能にするためのバックエンド及びUIでの表示・選択機能の追加）</li>
                <li>新予測モデルとして、GBDT回帰モデルを追加</li>
                <li>新予測モデルとして、過去64週分のデータの平均・分散を用いた古典在庫理論モデルを追加</li>
            </ul>
            `
    },
    {
        version: '0.4.4',
        date: '2024/12/06 22:270',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>フォーマットによってファイルのアップロードに失敗する不具合を修正</li>
            </ul>
            `
    },
    {
        version: '0.4.3',
        date: '2023/3/28 11:40',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>チャートにおいて稀に生じるstocksのsolid peak発生の不具合の修正</li>
            </ul>
            `
    },
    {
        version: '0.4.2',
        date: '2023/3/08 18:30',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>チャートにおける不具合の修正</li>
            </ul>
            `
    },
    {
        version: '0.4.1',
        date: '2023/1/31 11:55',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>チャートにおける表示期間不具合の修正</li>
            </ul>
            `
    },
    {
        version: '0.4.0',
        date: '2023/1/31 11:40',
        message: 
            `
            【機能追加】<br>
            <ul>
                <li>ダッシュボード、チャートページを追加</li>
            </ul>
            `
    },
    {
        version: '0.3.12',
        date: '2023/1/31 10:30',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>内部的な商品IDの桁数を8桁に変更</li>
            </ul>
            `
    },
    {
        version: '0.3.11',
        date: '2022/12/12 12:50',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>アップロード①におけるエラーログ表示不具合の修正</li>
            </ul>
            `
    },
    {
        version: '0.3.10',
        date: '2022/4/15 14:20',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>内部的な商品IDの桁数を16桁に変更</li>
            </ul>
            `
    },
    {
        version: '0.3.9',
        date: '2022/2/2 19:40',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>2回目のデータアップロード時のエラーダイアログがうまく表示されないバグを修正</li>
            </ul>
            `
    },
    {
        version: '0.3.8',
        date: '2022/2/1 23:50',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>1回目のデータアップロード時に進行不能になるバグを修正</li>
            </ul>
            `
    },
    {
        version: '0.3.7',
        date: '2022/2/1 23:15',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>1回目のデータアップロード時に進行不能になるバグを修正</li>
            </ul>
            `
    },
    {
        version: '0.3.6',
        date: '2022/1/27 14:50',
        message: 
            `
            【機能追加】<br>
            <ul>
                <li>2回目のデータアップロード時に不正な値をチェックする機能の追加</li>
            </ul>
            `
    },
    {
        version: '0.3.5',
        date: '2022/1/21 15:45',
        message: 
            `
            【機能追加】<br>
            <ul>
                <li>1回目のデータアップロード時に不正な値をチェックする機能の追加</li>
            </ul>
            `
    },
    {
        version: '0.3.4',
        date: '2021/12/1 17:25',
        message: 
            `
            【機能修正】<br>
            <ul>
                <li>発注テーブル画面にて異なる週・版の表示および編集・ダウンロードのサポート</li>
                <li>データアップロード画面ステップ１にて、予測週を未来に設定することの無効化</li>
            </ul>
            `
    },
    {
        version: '0.3.3',
        date: '2021/11/18 14:15',
        message: 
            `
            【機能修正】<br>
            <ul>
                <li>発注量計算用週数の変更と発注納期の前倒し機能を有効化(FP=1のみ無視）</li>
            </ul>
            `
    },
    {
        version: '0.3.2',
        date: '2021/11/17 0:00',
        message: 
            `
            【機能修正】<br>
            <ul>
                <li>発注量計算用週数の変更と発注納期の前倒し機能を無効化</li>
            </ul>
            `
    },
    {
        version: '0.3.1',
        date: '2021/11/16 10:10',
        message: 
            `
            【不具合修正】<br>
            <ul>
                <li>発注テーブルの一部カラムの説明文言を追加または正しい内容に修正</li>
            </ul>
            `
    },
    {
        version: '0.3.0',
        date: '2021/11/16 01:10',
        message: 
            `
            【仕様変更】<br>
            ★2021/11/11のミーティング@八幡ねじ にて、発注関連のロジックを見直し
            <ul>
                <li>カスタムルールの刷新</li>
                <li>発注量計算ロジックの変更（必要に応じたマスタ設定週数の上書き）</li>
                <li>（上記に関連し）発注納期の前倒し機能</li>
                <li>機械学習モデル出力の累積事後確率を発注テーブルのレコード詳細画面にて棒グラフとして可視化</li>
            </ul>
            `
    },
    {
        version: '0.2.11',
        date: '2021/11/08 16:20',
        message: 
            `
            【不具合修正】
            <ul>
                <li>発注量（計算）の計算処理の誤りを修正</li>
            </ul>
            `
    },
    {
        version: '0.2.10',
        date: '2021/10/01 11:10',
        message: 
            `
            【機能追加】
            <ul>
                <li>商品マスタ用個別アップロードフォーム実装（開発者用）</li>
            </ul>
            `
    },
    {
        version: '0.2.9',
        date: '2021/09/24 14:00',
        message: 
            `
            【不具合修正】
            <ul>
                <li>発注量計算用週数の計算ロジックを修正</li>
                <li>minimum_order_numにしたがう補正条件を発注量=0の商品に対して適用しないよう修正</li>
            </ul>
            `
    },
    {
        version: '0.2.8',
        date: '2021/09/22 10:20',
        message: 
            `
            【不具合修正】
            <ul>
                <li>minimum_order_numをケースor箱数からバラ数の扱いに変更</li>
            </ul>
            `
    },
    {
        version: '0.2.7',
        date: '2021/09/20 0:10',
        message: 
            `
            【不具合修正】
            <ul>
                <li>発注納期計算アルゴリズムを修正（土曜日と日曜日に重ならないようにする）</li>
            </ul>
            【機能追加】
            <ul>
                <li>出力csv（発注テーブル2）のカラム文言を変更（「安全在庫」→「発注点（１週）」など） / 発注単位カラムを追加</li>
                <li>shipments.csvアップロード時に実績または引当データが含まれないときにアラートを表示</li>
                <li>調整ルール１を変更（発注数が3カ月分を上回り、発注数（計算）が1より大きい場合、発注数は1）</li>
                <li>商品マスタの予測週数パラメータ（forecast_period）を発注点予測用（forecast_period）と発注量計算用（foreacast_period_ship）に変更。発注量計算用の週数値が存在すればそちらを優先的に発注量計算に用いる仕様に変更</li>
                <li>最小発注量パラメータ（minimum_order_num）を追加。この値がある場合、発注量をこの値まで切り上げる</li>
            </ul>
            `
    },
    {
        version: '0.2.6',
        date: '2021/09/17 11:30',
        message: 
            `
            【不具合修正】
            <ul>
                <li>手動発注量入力値と発注テーブル表示値の不整合</li>
            </ul>
            【機能追加】
            <ul>
                <li>出力csv（発注テーブル2）に発注点2週およびバラ数のカラムを追加</li>
            </ul>
            `
    },
    {
        version: '0.2.5',
        date: '2021/09/02 22:00',
        message: 
            `
            【機能追加】
            <ul>
                <li>行フィルタ機能追加</li>
            </ul>
            `
    },
    {
        version: '0.2.4',
        date: '2021/08/31 21:05',
        message: 
            `
            【不具合修正】
            <ul>
                <li>商品マスタアップロード時に「許可されていない空欄」が不当に表示される現象</li>
                <li>開発者用API「TABLES_GET_EDITED」にかかる長い計算時間を短縮</li>
            </ul>
            `
    },
    {
        version: '0.2.3',
        date: '2021/08/26 16:50',
        message: 
            `
            【機能追加】
            <ul>
                <li>開発者用API追加（TABLES_GET_EDITED）</li>
            </ul>
            `
    },
    {
        version: '0.2.2',
        date: '2021/08/23 17:15',
        message:
            `
            【不具合修正】
            <ul>
                <li>
                    商品マスタに単価情報のない商品に起因する発注テーブル作成エラー<br>
                    → 単価情報がなければテーブルに反映させないよう変更
                </li>
            </ul>
            【機能追加】
            <ul>
                <li>本システム更新履歴ページの追加</li>
                <li>発注テーブルページでの手動発注量変更</li>
                <li>products.csvアップロード時の簡易な値チェック</li>
            </ul>
            `
    },
    {
        version: '0.2.1',
        date: '2021/08/19',
        message: 'システムのバージョン管理開始'
    },
];

export const currentVersion = logs[0].version;
