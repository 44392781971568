<template>
    <div>
        <v-app-bar
            app
            dark
            color="primary">
            <v-app-bar-nav-icon @click="toggleDrawer" />
            <v-toolbar-title>八幡ねじ 在庫管理システム v{{ currentVersion }}</v-toolbar-title>
            <v-spacer />
            <div v-if="weekInfo">
                <b>{{ weekInfo.now.year }}年{{ weekInfo.week }}週</b>
                （{{ weekInfo.from.year }}/{{ weekInfo.from.month }}/{{ weekInfo.from.day }}({{ weekdays[weekInfo.from.weekday] }})〜
                {{ weekInfo.to.year }}/{{ weekInfo.to.month }}/{{ weekInfo.to.day }}({{ weekdays[weekInfo.to.weekday] }})
                ）
            </div>
        </v-app-bar>

        <v-navigation-drawer
            app
            v-model="showDrawer"
            :mini-variant="miniDrawer"
            width="300"
            >
            <v-layout column fill-height>
                <v-list flat>
                    <v-subheader v-if="!miniDrawer">Main menu</v-subheader>

                    <v-list-item color="primary" v-for="item in menuItems" :key="item.title" :to="item.to">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list subheader v-if="loggedIn && hasAdminAccess">
                    <v-subheader>Admin menu ※準備中</v-subheader>
                </v-list>

                <v-spacer></v-spacer>

                <v-list>
                    <v-list-item v-if="loggedIn" @click="logout">
                        <v-list-item-action>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>ログアウト</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="switchMiniDrawer">
                        <v-list-item-action>
                            <v-icon v-html="miniDrawer ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>折りたたむ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-layout>
        </v-navigation-drawer>

        <v-main>
            <keep-alive>
                <router-view :duct="duct" />
            </keep-alive>
        </v-main>
    </div>
</template>

<script>
import { YhtDuct } from '@/libs/yht'
import { currentVersion } from '@/libs/version'

export default {
    data: () => ({
        currentVersion,
        duct: new YhtDuct(),
        showDrawer: true,
        miniDrawer: true,
        loggedIn: false,
        hasAdminAccess: false,
        weekInfo: null,
        weekdays: ['','月','火','水','木','金','土','日'],
        menuItems: [
            //{ to: "/main/dashboard", icon: "mdi-view-dashboard", title: "ダッシュボード" },
            //{ to: "/main/profile/view", icon: "mdi-account", title: "ユーザー情報" },
            { to: "/main/forecast", icon: "mdi-chart-bar", title: "需要予測" },
            { to: "/main/order/view", icon: "mdi-table-large", title: "発注チェック" },
            { to: "/main/charts/dashboard", icon: "mdi-view-dashboard", title: "ダッシュボード" },
            { to: "/main/charts", icon: "mdi-chart-areaspline", title: "過去実績値チャート" },
            { to: "/main/version-log", icon: "mdi-tag-text-outline", title: "システム更新履歴" },
            //{ to: "/main/file/delete", icon: "mdi-delete", title: "受注実績データ削除" },
        ]
    }),
    methods: {
        toggleDrawer() {
            this.showDrawer = !this.showDrawer;
        },
        switchMiniDrawer() {
            this.miniDrawer = !this.miniDrawer;
        },
        logout() {
            window.localStorage.removeItem("YhtUsername");
            window.localStorage.removeItem("YhtPassword");
            window.location.href="/login";
        }
    },
    created() {
        this.duct.open("/ducts/wsd");
        this.duct.invokeOnOpen(async () => {
            const scheme = await this.duct.call(this.duct.EVENT.SCHEME_GET);
            this.duct = { ...this.duct, ...scheme.content };

            this.duct.addEventHandler(
                this.duct.EVENT.ACCOUNT_LOGIN,
                {
                    success: () => { this.loggedIn = true; },
                    error: () => { this.logout(); }
                }
            );
            this.duct.addEventHandler(
                this.duct.EVENT.WEEK_GET_INFO,
                {
                    success: (rid,eid,data) => { this.weekInfo = data; },
                    //error: (rid,eid,data) => { console.log(data); }
                }
            );

            const username = window.localStorage.getItem("YhtUsername");
            const password = window.localStorage.getItem("YhtPassword");
            if(username && password) {
                this.duct.send(this.duct.nextRid(), this.duct.EVENT.ACCOUNT_LOGIN, { username, password });
            } else {
                window.location.href="/login";
            }

            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_GET_INFO, { time: new Date().getTime()/1000 });
        });
    }
}
</script>
